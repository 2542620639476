@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../resources/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../resources/fonts/Poppins-Regular.ttf");
}

#myHeader {
  font-family: "Montserrat Regular";

  & .MuiButton-root {
    width: 170px;
    height: 30px;
    background: $color-azul;
    color: #fff !important;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    border-radius: 0px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Montserrat Regular";

    &:hover {
      background: transparent !important;
      border: 2px solid $color-azul !important;
      color: $color-azul !important;
    }
  }
}

.header {
  position: sticky !important;
  top: 0;
  left: 0;
  display: list-item;
  z-index: 99;
  padding: 10px 0;
}

.logo {
  width: 170px;
}

.bg-azul {
  background-color: rgba(248 247 247 / 90%) !important;
  transition: all 1s ease-in;
  color: #464646 !important;
}

.transform-img {
  content: url(../../resources/images/header/logo.svg);
}

.text-header-sticky {
  color: #fff !important;
}

.MuiToolbar-regular {
  transition: all 0.5s ease-in;
  background-color: transparent;
}

.alert {
  background: linear-gradient(90deg, rgba(225, 6, 19, 1) 34%, rgba(243, 143, 4, 1) 100%) !important;
  padding: 15px;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;

  & img {
    margin-right: 15px;
    width: 40px;
  }

  & .title {
    font-size: 14px;
    font-weight: bold;
    font-family: "Poppins Regular";
    text-align: left;
  }

  & .title-conference {
    font-size: 12px;
    font-family: "Poppins Regular";
  }

  & .btn-alert {
    background-color: red;
    border-radius: 10px;
    color: #fff;
    margin-left: 10px;
    font-size: 12px;

    &:hover {
      background-color: red;
      border-radius: 10px;
      color: #fff;
      margin-left: 10px;
      font-size: 12px;
    }
  }
}

// .MuiBackdrop-root {
//     background-color: rgb(0 0 0 / 67%) !important;
// }

.btn-menu {
  width: 200px;
  padding: 10px;
}

.btn-inline {
  align-items: center;
  width: 100%;
  display: inline-flex;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.Menu-button-8 {
  width: 100% !important;
  text-align: left !important;
}

.MuiButton-label {
  display: block !important;
}

.icon-menu {
  padding: 10px;
}

.nav-links {
  // width: 100%;
  // display: inline-flex;
  font-family: "Poppins Regular";
  font-size: 14px;
  // color: #fff;
  display: flex;
  float: right;
  text-transform: uppercase;
}

.nav-links li {
  list-style-type: none;
  cursor: pointer;
  font-weight: bold;
  font-family: "Poppins Regular";
  color: $color-texto;
}

.border {
  border-bottom: 2px solid $color-azul;
  padding-bottom: 5px;
}

.nav-links li a {
  text-decoration: none;
  color: #464646 !important;
  margin-right: 20px;
  font-family: "Poppins Regular";
}

.nav-links li div {
  margin-right: 20px;
}

.img-fondo-menu {
  width: 100%;
  height: 50px;
  // background-image: url(../../Resources/imagenes/login/logo-b-talks.png);
  background-size: contain;
  background-repeat: no-repeat;
  /* position: absolute; */
  /* bottom: 0px; */
  left: 0;
  margin-top: 30px;
}

.header .MuiInputBase-root {
  height: 5px !important;
}

.header .MuiSelect-icon {
  top: calc(50% - 20px) !important;
  color: #339933 !important;
}

.header .MuiInputLabel-formControl {
  transform: translate(0, 3px) scale(1) !important;
}

.header .MuiFormLabel-root {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.header .MuiInput-underline:before,
.header .MuiInput-underline:after {
  border: none !important;
}

.header .MuiListItem-root.Mui-selected,
.header .MuiListItem-root.Mui-selected:hover {
  background-color: transparent !important;
}

.alerta-div {
  margin-bottom: 30px;

  & .texto-blanco {
    color: #464646 !important;
  }
}

@media screen and (max-width: 1000px) and (orientation: portrait) {
  .btn-menu {
    width: 100px;

  }

  .logo {
    width: 120px;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .btn-menu {
    width: 100px;
  }

  .logo {
    width: 120px;
  }

  .container-info {
    top: -12px;
    font-size: 14px;
    width: 147px;
    height: 39px;
  }

  .numero-info::after {
    content: "";
    position: relative;
    right: 28px;
    top: 49px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 0px solid transparent;
    border-top: 11px solid #168942;
  }

  .header {
    position: relative;
    top: -8px;
  }

  .contentenedor-chat {
    height: 400px;
  }
}