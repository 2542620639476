@import '../../../resources/scss/variables';

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../../../resources/fonts/Montserrat-Bold.ttf');
}

#homeDaring {
  overflow: hidden;
  margin-bottom: 40px;
  margin-top: 40px;

  & .tituloDarinVerde {
    margin-left: 100px;
    font-size: 40px;
    color: #319641;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 80px;
  }

  & h1 {
    font-size: 36px;
    color: $color-normal;
    font-family: 'Montserrat Bold';
  }

  & p {
    color: $color-texto;
  }

  & .seleccionar-pilar {
    margin-top: 40px;
    text-align: center;
    background: linear-gradient(90deg, #fdc901 37%, #319931 93%);
    padding: 20px 0;
    & h1 {
      color: #005f21;
    }
  }

  & .titulos {
    font-family: 'Monserrat Boldr';
    font-size: 36px;
    font-weight: bold;
    color: $color-hover;

    & span {
      background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  & .name-conferencista {
    display: inline-flex;
    margin-bottom: 30px;

    & .img-conferencista {
      width: 155px;
      height: 145px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 40px;
    }

    & .job-conferencista {
      font-weight: normal;

      font-size: 18px;

      & .name {
        // background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        color: $color-hover;
        font-size: 23px;
        font-weight: bold;
      }

      & .job {
        // background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        color: $color-hover;
        font-size: 16px;
        font-weight: bold;
      }
    }

    & .description-conferencista {
      color: $color-azul !important;

      font-size: 18px;
    }
  }
  & .contenedor-likes {
    display: flex;

    & .btn-likes {
      width: 50px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  #homeDaring {
    & .contenedor-streaming {
      & .estilo-streaming {
        width: 100%;
        height: auto;
      }
    }

    & .name-conferencista {
      display: inline-grid;
      text-align: center;

      & ul {
        text-align: left;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  #homeDaring {
    & .name-conferencista {
      display: inline-grid;
      text-align: center;

      & ul {
        text-align: left;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #homeDaring {
    & .name-conferencista {
      display: inline-flex;
      text-align: left;

      & ul {
        text-align: left;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
