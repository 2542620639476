@import '../../../resources/scss/variables';

@font-face {
	font-family: 'Montserrat Bold';
	src: url('../../../resources/fonts/Montserrat-Bold.ttf');
}

#usuarios {
	padding: 0 0 25px 0;

	& h1 {
		color: $color-hover;
		font-family: 'Montserrat Bold';
	}

	& .MuiButton-root {
		width: 170px;
		background: $color-azul;
		color: #fff !important;
		padding: 5px;
		cursor: pointer;
		font-size: 14px;
		text-align: center;
		border-radius: 0px;
		text-transform: capitalize;
		font-weight: bold;
		font-family: 'Montserrat Regular';
		float: right;
		border: 2px solid $color-azul !important;

		&:hover {
			background: transparent !important;
			border: 2px solid $color-azul !important;
			color: $color-azul !important;
		}
	}

	// & thead {
	//   & tr {
	//     background-color: red;
	//     color: #fff;
	//   }
	// }

	// & th {
	//   text-align: center;
	// }

	// & td {
	//   text-align: center;
	// }

	// // Scroll
	// & .scroll-container {
	//   width: 75vw; /* Ancho completo del contenedor */
	//   overflow-x: auto; /* Habilitar scroll horizontal */
	//   // border: 1px solid #ccc; /* Borde opcional para visualización */
	// }
	// & .fixed-size-content {
	//   width: 100vw; /* Ancho fijo del contenido */
	//   height: 900px; /* Altura fija del contenido */
	//   // background-color: #f0f0f0; /* Color de fondo opcional */
	// }

	// /* Estilos para scrollbars personalizados en WebKit */
	// & .scroll-container::-webkit-scrollbar {
	//   height: 12px; /* Altura del scrollbar horizontal */
	// }
	// & .scroll-container::-webkit-scrollbar-thumb {
	//   background: green; /* Color del thumb (parte que se mueve) */
	//   border-radius: 10px; /* Bordes redondeados del thumb */
	// }
	// & .scroll-container::-webkit-scrollbar-track {
	//   // background: #f1f1f1; /* Color de la pista (track) */
	//   border-radius: 10px; /* Bordes redondeados de la pista */
	// }
}
