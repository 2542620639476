@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../resources/fonts/Montserrat-Bold.ttf");
}

body {
  margin: 0;
  font-family: "Montserrat Regular";
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #fff;
  background-color: #fff !important;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* movimiento arriba abajo */
.up-to-down {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes play {
  100% {
    background-position: 0 -7110px;
  }
}

#root {
  overflow: hidden;
}

#root::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-position-x: center;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  // background-image: url(../../resources/images/bg.svg);
}

.btn-cerrar-popup {
  color: #fff;
  float: right;
  background: #2c6db4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.btn {
  line-height: 50px !important;
  height: 50px !important;
  text-align: center !important;
  width: 170px !important;
  cursor: pointer !important;
  background-color: $color-azul;
}

p,
h1,
h2,
h3 {
  font-family: "Montserrat Regular";
}

.full {
  width: 100%;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold !important;
}

.cursor {
  cursor: pointer;
}

.padre {
  display: table;

  & .hijos {
    display: table-cell;
    vertical-align: middle;
  }
}

.padres {
  display: table;

  & .hijoss {
    display: center;
    vertical-align: middle;
  }
}

.flex {
  display: flex;
}

.contenedor-streaming {
  width: 100%;
  text-align: center;
  // margin-bottom: 60px;

  & .estilo-streaming {
    width: 100%;
    height: 450px;
  }
}

.titulos {
  font-size: 36px;
  color: $color-azul;
  font-weight: 700;
}

.blanco {
  color: #fff !important;
}

/* width scroll */
::-webkit-scrollbar {
  width: 0px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt30 {
  margin-top: 10px;
}

.padding {
  padding: 15px;
}

// celular
@media screen and (max-width: 425px) and (orientation: portrait) {
  .titulos {
    font-size: 22px;
  }

  .contenedor-streaming {
    & .estilo-streaming {
      width: 100%;
      // height: auto;
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  .titulos {
    font-size: 22px;
  }

  .contenedor-streaming {
    & .estilo-streaming {
      width: 100%;
      // height: auto;
    }
  }
}

// tablet
@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {
  .contenedor-streaming {
    & .estilo-streaming {
      width: 100%;
      // height: auto;
    }
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background: #fff !important;
  }
}