@import "../../../../../resources/scss/variables";

@font-face {
  font-family: "Lato Regular";
  src: url("../../../../../resources/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../../../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../../../../resources/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../../../../resources/fonts/Montserrat-Light.ttf");
}

#certificate {
  overflow: hidden;
  padding: 50px 0;
  color: $color-texto;

  & #section-questions {
    & .titulos {
      color: $color-hover;
      font-size: 28px;
      font-family: "Montserrat Bold";

      & .subtexto {
        color: $color-texto;
        font-size: 22px;
        font-family: "Montserrat Regular";
      }
    }

    & input.MuiInputBase-input {
      font-family: "Montserrat Regular";
      color: $color-azul;
      font-size: 16px;
    }

    & .pregunta {
      color: $color-azul;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    & .MuiFormControlLabel-root {
      margin-bottom: 10px;
    }

    & .MuiTypography-body1 {
      font-family: "Montserrat Regular" !important;
    }

    & .MuiRadio-colorSecondary.Mui-checked {
      // background: rgba(225, 6, 19, 1) !important;
      color: $color-hover !important;
    }

    & .MuiSwitch-colorPrimary {
      // background: #e10613 !important;
      color: $color-hover !important;
    }

    & .MuiButton-root {
      width: 170px;
      background: $color-azul;
      color: #fff !important;
      padding: 5px;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      border-radius: 0px;
      text-transform: capitalize;
      font-weight: bold;
      font-family: "Montserrat Regular";

      &:hover {
        background: transparent !important;
        border: 2px solid $color-azul !important;
        color: $color-azul !important;
      }
    }
  }

  #reprobado {
    & .retro {
      background-color: #F8F7F7;
      padding-bottom: 30px;

      & .contenedor-retro {
        background: #fff;
        // margin: 50px;
        padding: 30px;
        margin-top: 20px;
        margin-bottom: 20px;

        & .titulo-retro {
          border: 5px;
          color: #fff;
          font-weight: bold;
          padding: 10px;
          border-radius: 10px;
          font-size: 15px;
          height: 50px;
          width: 200px;
          text-align: center;
          margin: auto;
        }

        & .morado {
          background-color: #892C85;
        }

        & .azul {
          background-color: #2D6DB4;
        }

        & .verde {
          background-color: #007F2D;
        }

        & .border {
          border-bottom: 1px solid #A5A6A8;
          width: 80%;
        }

        & .contenedor-retro-opcion {
          background-color: #F3F3F3;
          padding: 15px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }


      }
    }

    & .btn-reprobado {
      width: 100% !important;
      background: $color-azul;
      color: #fff !important;
      padding: 5px;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      border-radius: 0px;
      text-transform: capitalize;
      font-weight: bold;
      font-family: "Poppins Regular";

      &:hover {
        background: transparent !important;
        border: 2px solid $color-azul !important;
        color: $color-azul !important;
      }
    }

    & .contenedor-calif {
      background: linear-gradient(90deg, #319931 65.63%, #FDC901 100%) !important;
      border-radius: 7px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      padding: 15px;
      font-family: "Montserrat Bold";
      font-size: 22px;
    }
  }

  & #aprobado {
    & .retro {
      background-color: #F8F7F7;
      padding-bottom: 30px;

      & .contenedor-retro {
        background: #fff;
        // margin: 50px;
        padding: 30px;
        margin-top: 20px;
        margin-bottom: 20px;

        & .titulo-retro {
          border: 5px;
          color: #fff;
          font-weight: bold;
          padding: 10px;
          border-radius: 10px;
          font-size: 15px;
          height: 50px;
          width: 200px;
          text-align: center;
          margin: auto;
        }

        & .morado {
          background-color: #892C85;
        }

        & .azul {
          background-color: #2D6DB4;
        }

        & .verde {
          background-color: #007F2D;
        }

        & .border {
          border-bottom: 1px solid #A5A6A8;
          width: 80%;
        }

        & .contenedor-retro-opcion {
          background-color: #F3F3F3;
          padding: 15px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }


      }
    }

    & .btn-reprobado {
      width: 100% !important;
      background: $color-azul;
      color: #fff !important;
      padding: 5px;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      border-radius: 0px;
      text-transform: capitalize;
      font-weight: bold;
      font-family: "Poppins Regular";

      &:hover {
        background: transparent !important;
        border: 2px solid $color-azul !important;
        color: $color-azul !important;
      }
    }

    & .contenedor-calif {
      background: linear-gradient(90deg, #319931 65.63%, #FDC901 100%) !important;
      border-radius: 7px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      padding: 15px;
      font-family: "Montserrat Bold";
      font-size: 22px;
    }
  }

  & #section-certificate {
    & .MuiButton-root {
      width: 170px;
      background: $color-azul;
      color: #fff !important;
      padding: 5px;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      border-radius: 0px;
      text-transform: capitalize;
      font-weight: bold;
      font-family: "Montserrat Regular";

      &:hover {
        background: transparent !important;
        border: 2px solid $color-azul !important;
        color: $color-azul !important;
      }
    }

    & .titulos {
      color: $color-hover;
      font-size: 28px;
      font-family: "Montserrat Bold";
    }

    & .subtitulos {
      color: #fff;
      color: $color-hover;
      font-family: "Montserrat Bold";
      // font-weight: bold;
    }

    & .text {
      color: $color-texto;
      font-weight: bold;
      // font-family: "Montserrat Regular";
    }

    & .certificate {
      width: 100%;
      // height: 785px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      position: relative;
      transform: scale(1);

      & .name {
        position: absolute;
        top: 46%;
        // left: 25%;
        color: $color-hover;
        font-size: 32px;
        font-weight: bold;
        font-family: "Montserrat Bold";
        text-transform: capitalize;
      }

      & .name-two {
        font-size: 12px;
        color: #54555b;
        // font-family: "Montserrat Regular";
        top: 90%;
        // left: 35%;
        position: absolute;
        text-transform: capitalize;
      }

      & .job {
        font-size: 12px;
        color: #54555b;
        // font-family: "Montserrat Regular";
        top: 92%;
        left: 35%;
        position: absolute;
        text-transform: capitalize;
      }
    }

    & .btn-download {
      background: $color-hover;
      color: #fff;
      width: 150px;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
      float: right;

      &:hover {
        background: $color-hover;
      }
    }

    & .contact-us {
      background: linear-gradient(90deg, #319931 0%, #2D6DB4 54.74%);
      color: #fff;
      padding: 60px 0;
      position: relative;

      & .title-thank-message {
        font-size: 30px;
        font-weight: bold;
        // font-family: "Montserrat Regular";
      }

      & .text-message {
        font-family: "Montserrat Light";

        & span {
          font-weight: bold;
        }
      }

      & .btn-contact-us {
        background: #fff;
        color: $color-hover;
        width: 150px;
        margin: auto;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
      }
    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: 1px solid #fff !important;
  }
}

@media screen and (max-width: 825px) and (orientation: portrait) {
  #certificate {
    & .MuiFormControlLabel-root {
      margin-bottom: 20px;
    }

    & #retro {
      & .contenedor-retro {
        margin: 0px;

        & p {
          text-align: center;
          margin-top: 0;
        }
      }
    }

    & #section-certificate {
      & .certificate {
        background-size: contain;
        // height: 240px;

        & .name {
          top: 43% !important;
          // left: 25% !important;
          font-size: 12px !important;
        }

        & .name-two {
          top: 73% !important;
          left: 34% !important;
          font-size: 8px !important;
        }

        & .job {
          top: 77% !important;
          left: 34% !important;
          font-size: 8px !important;
        }
      }

      & .btn-download {
        float: none;
      }
    }

    & #reprobado {
      & .img-top {
        width: 100%;
      }
    }

    & #aprobado {
      & .img-top {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  #certificate {
    & .MuiFormControlLabel-root {
      margin-bottom: 20px;
    }

    & #section-questions {
      & .titulos {
        font-size: 18px;
      }
    }

    & #retro {
      & .contenedor-retro {
        margin: 0px;

        & p {
          text-align: center;
          margin-top: 0;
        }
      }
    }

    & #section-certificate {
      & .certificate {
        // background-size: contain;
        // height: 440px;

        & .name {
          top: 43% !important;
          // left: 28% !important;
          font-size: 22px !important;
        }

        & .name-two {
          top: 74% !important;
          left: 34% !important;
          font-size: 10px !important;
        }

        & .job {
          top: 77% !important;
          left: 34% !important;
          font-size: 10px !important;
        }
      }
    }

    & #reprobado {
      & .img-top {
        width: 100%;
      }
    }

    & #aprobado {
      & .img-top {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {
  #certificate {
    & #reprobado {
      & .img-top {
        width: 100%;
      }
    }

    & #aprobado {
      & .img-top {
        width: 100%;
      }
    }
  }
}