@import "../../../resources/scss/variables";

@font-face {
    font-family: "Montserrat Bold";
    src: url("../../../resources/fonts/Montserrat-Bold.ttf");
}

#testimoniosPanel {
    padding: 0 0 25px 0;

    & h1 {
        color: $color-hover;
        font-family: "Montserrat Bold";
    }

    & thead {
        & tr {

            background-color: red;
            color: #fff;
        }
    }

    & th {
        text-align: center;
    }

    & td {
        text-align: left;
    }

    & .MuiButton-root {
        width: 170px;
        background: $color-azul;
        color: #fff !important;
        padding: 5px;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        border-radius: 0px;
        text-transform: capitalize;
        font-weight: bold;
        font-family: "Montserrat Regular";

        &:hover {
            background: transparent !important;
            border: 2px solid $color-azul !important;
            color: $color-azul !important;
        }
    }
}