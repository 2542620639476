@import "../../../resources/scss/variables";

@font-face {
    font-family: "Montserrat Regular";
    src: url("../../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat Bold";
    src: url("../../../resources/fonts/Montserrat-Bold.ttf");
}

#broshure {
    font-family: "Montserrat Regular";
    color: $color-texto;

    // margin-top: 30px;
    & section {
        display: grid;
    }

    & #seccion1 {
        height: 100vh;
        background-image: url(../../../resources/images/broshure/seccion1/broshure-seccion-1-banner.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    & #seccion2 {
        padding: 30px 0;
        background: linear-gradient(270deg, #2D77B4 0.5%, #319931 31.23%);
        color: #fff;
        font-size: 33px;
        font-weight: bold;

        & .sizes {
            width: 100%;
        }
    }

    & #seccion3 {
        padding: 100px 0;
        background-image: url(../../../resources/images/broshure/seccion3/bg.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        background-attachment: fixed;

        & img {
            margin-Bottom: 40px;
        }
    }

    & #seccion5 {
        background-image: url(../../../resources/images/broshure/seccion5/bg.svg);
        background-repeat: no-repeat;
        background-attachment: fixed;
        padding: 100px 0;

        & .left {
            float: left;
        }
    }

    & #seccion6 {
        padding: 30px 0;
        color: #319931;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
    }

    & #seccion7 {
        background-color: #F8F7F7;
        padding: 100px 0;
    }

    & #seccion8 {
        padding: 30px 0;
        background: linear-gradient(270deg, #2D77B4 0.5%, #319931 31.23%);
        color: #fff;
        font-size: 33px;
        font-weight: bold;
    }

    & #seccion9 {
        padding: 100px 0;

        & .texto {
            font-family: "Montserrat Regular";
            font-size: 25px;
            font-weight: bold;
            margin: 40px 0;
            text-align: center;
        }
    }

    & #seccion10 {
        background: linear-gradient(270deg, #2D77B4 0.5%, #319931 31.23%);
        background-image: url(../../../resources/images/broshure/seccion10/bg.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 200px 0;
    }

    & #seccion11 {
        background-color: #F8F7F7;
        padding: 100px 0 0 0;
        position: relative;

        & .titulo {
            color: #2D6DB4;
            font-family: "Montserrat Bold";
            font-size: 28px;
            margin-bottom: 50px;
        }

        & .container-btns {
            overflow: hidden;
            cursor: pointer;
        }

        & .border {
            border-bottom: 5px solid #319641;
        }

    }

    & #seccion12 {
        padding: 100px 0;
        background: linear-gradient(270deg, #2D77B4 0.5%, #319931 31.23%);
    }

    & #seccion13 {
        padding: 100px 0;
        background-color: #F8F7F7;
    }

    & #seccion14 {
        padding: 0 0 100px 0;
        background-color: #F8F7F7;
    }

    & #seccion15 {
        padding: 100px 0;

        & .alice-carousel__prev-btn-item,
        .alice-carousel__next-btn-item {
            color: #465798 !important;
            font-size: 45px !important;
            font-weight: bold !important;
        }

        & .alice-carousel__prev-btn {
            text-align: center !important;
        }

        & .fullwrap {
            position: relative;
            /* required for (c2) */
            max-width: 500px;
            /* optional */
            overflow: hidden;
            margin: 0 10px;
            // margin: auto;
        }

        /* (B) RESPONSIVE IMAGE */
        & .fullwrap img {
            width: 100%;
        }

        /* (C) CAPTION */
        & .fullcap {
            /* (C1) DIMENSIONS */
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 15px;

            /* (C2) POSITION */
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            padding-top: 15%;

            /* (C3) COLORS */
            background-color: rgba(45, 109, 180, 0.7);
            color: white;
        }

        /* (D) SHOW/HIDE */
        & .fullcap {
            visibility: none;
            opacity: 0;
            transition: opacity 0.3s;

            & .titulo {
                font-weight: bold;
                margin-bottom: 20px;
            }
        }

        & .fullwrap:hover .fullcap {
            visibility: visible;
            opacity: 1;
        }
    }

    & #seccion16 {
        padding: 100px 0 0 0;
        background-color: #F8F7F7;
    }

    & #seccion17 {
        padding: 30px 0;
        background: linear-gradient(270deg, #2D77B4 0.5%, #319931 31.23%);
    }

    & #seccion18 {
        padding: 100px 0;
        background-color: #F8F7F7;
    }

    & #seccion19 {
        padding: 100px 0;
        background-image: url(../../../resources/images/broshure/seccion19/bg.svg);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: right;

        & .contenedor-correo {
            position: relative;
            display: grid;

            & .link {
                position: absolute;
                top: 44%;
                left: 28%;
                width: 51%;
                height: 8%;
                cursor: pointer;
            }
        }
    }

    & #seccion20 {
        padding: 100px 0;
    }

}

#bienestar {
    // margin: 60px 0;

    & .border-broshure {
        border-top: 15px solid #F8F7F7;
        border-left: 15px solid #F8F7F7;
        border-right: 15px solid #F8F7F7;
        border-bottom: 15px solid #F8F7F7;
    }

    & .border-broshure-bottom {
        border-top: 0px solid #F8F7F7;
        border-bottom: 15px solid #F8F7F7;
        border-left: 15px solid #F8F7F7;
        border-right: 15px solid #F8F7F7;
    }


    & .cuadro2Texto-bienestar {
        width: 95%;
    }

}

.border-c1 {
    border-top: 15px solid #F8F7F7;
    border-left: 15px solid #F8F7F7;
    border-right: 15px solid #F8F7F7;
}

.border-c2 {
    border-top: 15px solid #F8F7F7;
    border-right: 15px solid #F8F7F7;
}

.border-c3 {
    border-top: 15px solid #F8F7F7;
    border-bottom: 15px solid #F8F7F7;
    border-left: 15px solid #F8F7F7;
    border-right: 15px solid #F8F7F7;
}

.border-c4 {
    border-top: 15px solid #F8F7F7;
    border-bottom: 15px solid #F8F7F7;
    border-right: 15px solid #F8F7F7;
}

.border-c5 {
    border-bottom: 15px solid #F8F7F7;
    border-left: 15px solid #F8F7F7;
    border-right: 15px solid #F8F7F7;
}

.border-c6 {
    border-bottom: 15px solid #F8F7F7;
    border-right: 15px solid #F8F7F7;
}

.border-c7 {
    border-left: 15px solid #F8F7F7;
    border-right: 15px solid #F8F7F7;
}

#inclusion {
    // margin: 60px 0;
}

#gestion {
    // margin: 60px 0;
}

#comportamientos {
    // margin: 60px 0;

}

.cuadro-blanco {
    padding: 15px;
    background-color: #fff;
}

.bg-expertos {
    background: #F8F7F7;
    background-image: url(../../../resources/images/broshure/seccion11/bienestar/bg-expertos-bienestar.svg);
    // width: 100vw !important;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px;
    // overflow-y: scroll;

    & .titulo-expertos-popup {
        color: #2D6DB4;
        font-size: 33px;
        font-family: "Montserrat Bold";
        margin-bottom: 20px;
    }

    & .contenedor-experto {
        box-shadow: 0px 0px 11px 0px rgba(199, 199, 199, 0.75);
        -webkit-box-shadow: 0px 0px 11px 0px rgba(199, 199, 199, 0.75);
        -moz-box-shadow: 0px 0px 11px 0px rgba(199, 199, 199, 0.75);
        border-radius: 15px;
        margin-right: 15px;
        height: 250px;

        & .relative {
            position: relative;

            & .titulo {
                right: -10%;
                position: absolute;
            }

            & .linkeding {
                position: absolute;
                right: 5%;
                top: 40%;
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width: 325px) and (max-width: 819px) and (orientation: portrait) {
    #broshure {

        & .border-c1,
        & .border-c2,
        & .border-c3,
        & .border-c4,
        & .border-c5,
        & .border-c6 {
            border-top: 15px solid #F8F7F7;
            border-left: 15px solid #F8F7F7;
            border-right: 15px solid #F8F7F7;
        }

        #bienestar {
            & .cuadro2Texto-bienestar {
                width: 98%;
            }
        }

        #inclusion {
            & .cuadro2TextoInclusion {
                width: 64%;
            }
        }

        #gestion {
            & .img2TextoGestion {
                width: 100%;
            }
        }

        #comportamientos {
            & .cuadro2TextoComportamientos {
                width: 100%;
            }
        }

        & #seccion2 {
            & .size {
                width: 100%;
            }
        }

        & #seccion3 {
            padding: 30px 0 0 0;
        }

        & #seccion5 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        & #seccion7 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        & #seccion9 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        & #seccion10 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        #seccion12 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        #seccion13 {
            padding: 30px 0 0 0;

            & .size {
                width: 100%;
            }
        }

        #seccion14 {
            padding: 30px 0 0 0;
        }


        #seccion15 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        #seccion16 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        #seccion17 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        #seccion18 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }

        #seccion19 {
            padding: 30px 0;

            & .link {
                position: absolute;
                top: 36%;
                left: 7%;
                width: 84%;
                height: 8%;
                cursor: pointer;
            }
        }

        #seccion20 {
            padding: 30px 0;

            & .size {
                width: 100%;
            }
        }
    }

    .contenido-popup1 {
        width: 100%;
    }

    .bg-expertos {
        width: 100% !important;
        background-size: contain;
        padding: 0;

        & .contenedor-experto {
            height: 250px;
            margin-right: 0;
            margin-bottom: 30px;

            & .relative {
                & .titulo {
                    left: 7%;
                    position: absolute;
                    top: -22%;
                }

                & .linkeding {
                    top: 21%;
                }
            }
        }
    }
}

.contenido-bienestar-popup1 {
    width: 100%;

}

@media screen and (min-width: 820px) and (max-width: 1024px) and (orientation: portrait) {
    #broshure {
        #bienestar {
            & .cuadro2Texto-bienestar {
                width: 98%;
            }
        }

        #inclusion {
            & .cuadro2TextoInclusion {
                width: 100%;
            }
        }

        #gestion {
            & .img2TextoGestion {
                width: 100%;
            }
        }

        #comportamientos {
            & .cuadro2TextoComportamientos {
                width: 100%;
            }
        }

        .contenido-bienestar-popup1 {
            width: 100%;
        }

        .contenido-popup1 {
            width: 100%;
        }

        .bg-expertos {
            // width: 100% !important;
            background-size: contain;
            padding: 0;

            & .contenedor-experto {
                height: 300px;
            }
        }

        #seccion7 {
            & .size {
                width: 100%;
            }
        }

        #seccion12 {
            & .size {
                width: 100%;
            }
        }

        #seccion13 {
            & .size {
                width: 100%;
            }
        }

        #seccion16 {
            & .size {
                width: 100%;
            }
        }
    }
}