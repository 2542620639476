@import "../../../../resources/scss/variables";

@font-face {
    font-family: "Montserrat Regular";
    src: url("../../../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat Bold";
    src: url("../../../../resources/fonts/Montserrat-Bold.ttf");
}

#crearTestimonios {
    padding: 0 0 25px 0;

    & h1 {
        color: $color-hover;
        font-family: "Montserrat Bold";
    }

    & .swal2-confirm {
        background-color: #C1212A !important;
    }

    & .MuiButton-root {
        width: 170px;
        background: $color-azul;
        color: #fff !important;
        padding: 5px;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        border-radius: 0px;
        text-transform: capitalize;
        font-weight: bold;
        font-family: "Montserrat Regular";

        &:hover {
            background: transparent !important;
            border: 2px solid $color-azul !important;
            color: $color-azul !important;
        }
    }
}