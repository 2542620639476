.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6705882352941) !important;
}

.MuiDialog-paper {
    margin: 0 !important;
}

.MuiDialog-paperWidthSm {
    width: initial !important;
    min-width: initial !important;
    max-width: initial !important;
}