@import '../../../../resources/scss/variables';

#insignia {
	& .btn-azul {
		width: 170px;
		background: $color-azul;
		color: #fff !important;
		padding: 5px;
		cursor: pointer;
		font-size: 14px;
		text-align: center;
		border-radius: 0px;
		text-transform: capitalize;
		font-weight: bold;
		font-family: 'Montserrat Regular';

		&:hover {
			background: transparent !important;
			border: 2px solid $color-azul !important;
			color: $color-azul !important;
		}
	}

	& .certificate {
		width: 100%;
		// height: 785px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		position: relative;
		transform: scale(1);

		& .name {
			position: absolute;
			top: 50%;
			// left: 25%;
			color: $color-hover;
			font-size: 32px;
			font-weight: bold;
			font-family: 'Montserrat Bold';
			text-transform: capitalize;
		}
	}
}

@media only screen and (max-width: 600px) {
	#insignia {
		& .certificate {
			background-size: contain;

			& .name {
				top: 49% !important;
				font-size: 12px !important;
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	#insignia {
		& .certificate {
			background-size: contain;

			& .name {
				top: 49% !important;
				font-size: 22px !important;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	#insignia {
		& .certificate {
			background-size: contain;

			& .name {
				top: 49% !important;
				left: 40%;
				font-size: 28px !important;
			}
		}
	}
}

@media only screen and (min-width: 992px) {
	#insignia {
		& .certificate {
			// background-size: contain;
			& .name {
				left: 49%;
				top: 50% !important;
				font-size: 32px !important;
			}
		}
	}
}

@media only screen and (min-width: 1200px) {
}

// @media screen and (max-width: 825px) and (orientation: portrait) {
// 	#insignia {
// 		& .certificate {
// 			background-size: contain;

// 			& .name {
// 				top: 43% !important;
// 				font-size: 12px !important;
// 			}
// 		}
// 	}
// }
// @media screen and (max-width: 880px) and (orientation: landscape) {
// 	#insignia {
// 		& .certificate {
// 			// background-size: contain;
// 			// height: 440px;

// 			& .name {
// 				top: 43% !important;
// 				// left: 28% !important;
// 				font-size: 22px !important;
// 			}
// 		}
// 	}
// }
