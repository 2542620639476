.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;

  & .visible {
    opacity: 1;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  & .hidden {
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
}
