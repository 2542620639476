@import "../../../resources/scss/variables";

@font-face {
  font-family: "Lato Regular";
  src: url("../../../resources/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../../resources/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../../resources/fonts/Montserrat-Bold.ttf");
}

#home {
  overflow: hidden;
  margin-bottom: 40px;

  & h1 {
    font-size: 36px;
    color: $color-normal;
    font-family: "Montserrat Bold";

  }

  & p {
    color: $color-texto;
  }

  & .scroll-gif {
    width: 100%;
  }

}

// celular
@media screen and (max-width: 820px) and (orientation: portrait) {
  #home {
    & .scroll-gif {
      width: 15%;
    }

  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  #home {}
}

// tablet
@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {
  #home {}
}