@import "../../../resources/scss/variables";

@font-face {
    font-family: "Montserrat Bold";
    src: url("../../../resources/fonts/Montserrat-Bold.ttf");
}

#testimonios {
    background-color: #F8F7F7;
    padding: 50px 0;

    & h1 {
        font-family: "Montserrat Bold";
        font-weight: bold;
        color: #319931;
    }

    & p {
        color: $color-texto;
    }

    & .contenedor-testimonio {
        background-color: #fff;
        color: $color-texto;
        margin-right: 20px;
        height: 350px;
        overflow: hidden;

        & .border-top {
            background: linear-gradient(90deg, #2D6DB4 3.08%, #319931 96.62%);
            height: 10px;
            margin-bottom: 20px;
        }

        & .texto-testimonio {
            padding: 20px;
            text-align: center;
            height: 210px;
            width: 88%;
            word-break: break-word;
        }

        & .info-testimonio {
            background: linear-gradient(90deg, #2D6DB4 3.08%, #319931 96.62%);
            color: #fff;
            text-align: center;
            padding: 15px 0;
            word-break: break-word;

            & .nombre {
                font-weight: bold;
                font-family: "Montserrat Bold";
                text-transform: capitalize;
                word-break: break-word;
            }
        }
    }
}

// @media screen and (max-width: 765px) and (orientation: portrait) {}

@media screen and (min-width: 325px) and (max-width: 819px) and (orientation: portrait) {
    #testimonios {
        & .contenedor-testimonio {
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {}

// #form-testimonio {

//     & .color-verde {
//         font-family: "Montserrat Bold";
//         color: #319931;
//     }

//     & .MuiButton-root {
//         width: 170px;
//         background: $color-azul;
//         color: #fff !important;
//         padding: 5px;
//         cursor: pointer;
//         font-size: 14px;
//         text-align: center;
//         border-radius: 0px;
//         text-transform: capitalize;
//         font-weight: bold;
//         font-family: "Poppins Regular";

//         &:hover {
//             background: transparent !important;
//             border: 2px solid $color-azul !important;
//             color: $color-azul !important;
//         }
//     }
// }