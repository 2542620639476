@import '../../../resources/scss/variables';

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../../../resources/fonts/Montserrat-Bold.ttf');
}

#pilares {
  margin-top: 0px;
  overflow: hidden;

  & .tituloCarinVerde {
    margin-left: 100px;
    font-size: 40px;
    color: #319641;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 80px;
  }

  & .btn-pilar {
    width: 250px;
  }

  & .border {
    border-bottom: 5px solid #319641;
  }

  & .titulo-pilares {
    padding: 30px 0;
    // background: linear-gradient(270deg, #2D77B4 0.5%, #319931 31.23%);
    background: linear-gradient(90deg, #319931 0%, #2d6db4 54.74%);
    color: #fff;
    margin-bottom: 30px;
    text-align: center;
    font-size: 28px;
    font-family: 'Montserrat Bold';
  }

  & .scroll-gif {
    width: 100%;
  }

  & #recursos-digitales {
    background-color: #f8f7f7;
    padding: 0 0 50px 0;
    margin-top: 50px;
    margin-bottom: 30px;

    & .scroll-gif {
      width: 100%;
    }

    & h1 {
      font-family: 'Montserrat Bold';
      font-weight: bold;
      color: #319931;
    }

    & .descripcion {
      font-family: 'Montserrat Bold';
      font-weight: bold;
      color: $color-texto;
      font-size: 22px;
    }

    & .fullwrap {
      position: relative;
      /* required for (c2) */
      max-width: 100%;
      /* optional */
      overflow: hidden;
      // margin: 0 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    /* (B) RESPONSIVE IMAGE */
    & .fullwrap img {
      width: 100%;
    }

    /* (C) CAPTION */
    & .fullcap {
      /* (C1) DIMENSIONS */
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      /* (C2) POSITION */
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding-top: 20%;

      /* (C3) COLORS */
      background-color: rgba(45, 109, 180, 0.7);
      color: white;
    }

    /* (D) SHOW/HIDE */
    & .fullcap {
      visibility: none;
      opacity: 0;
      transition: opacity 0.3s;

      & .titulo {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 28px;
        font-family: 'Montserrat Bold';
      }
    }

    & .fullwrap:hover .fullcap {
      visibility: visible;
      opacity: 1;
    }

    & .btn-caption {
      line-height: 50px !important;
      height: 50px !important;
      text-align: center !important;
      width: 250px !important;
      cursor: pointer !important;
      margin: auto;
    }

    & .btn-one {
      color: #fff !important;
      transition: all 0.3s !important;
      position: relative !important;
    }

    & .btn-one span {
      transition: all 0.3s !important;
    }

    & .btn-one::before {
      content: '' !important;
      position: absolute !important;
      bottom: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 1 !important;
      opacity: 0 !important;
      transition: all 0.3s !important;
      border-top-width: 1px !important;
      border-bottom-width: 1px !important;
      border-top-style: solid !important;
      border-bottom-style: solid !important;
      border-top-color: #fff !important;
      border-bottom-color: #fff !important;
      transform: scale(0.1, 1) !important;
    }

    & .btn-one:hover span {
      letter-spacing: 2px !important;
    }

    & .btn-one:hover::before {
      opacity: 1 !important;
      transform: scale(1, 1) !important;
    }

    & .btn-one::after {
      content: '' !important;
      position: absolute !important;
      bottom: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 1 !important;
      transition: all 0.3s !important;
      // background-color: rgba(255, 255, 255, 0.1) !important;
    }

    & .btn-one:hover::after {
      opacity: 0 !important;
      transform: scale(0.1, 1) !important;
    }

    & .contenedor-likes {
      display: flex;

      & .btn-likes {
        width: 50px;
        cursor: pointer;
      }
    }
  }

  & .retro-likes {
    background: linear-gradient(270deg, #2d77b4 0.5%, #319931 31.23%);
    margin-bottom: 30px;

    & .texto-feedback {
      font-size: 24px;
    }

    & .contenedor-likes {
      display: flex;

      & .btn-likes {
        width: 50px;
        cursor: pointer;
      }
    }
  }

  & .hei-life {
    background-color: #319641;
    color: #fff;
    text-align: left;
    border-radius: 10px;
    padding: 15px;
    word-break: break-word;
    cursor: pointer;
  }
}

@media screen and (min-width: 325px) and (max-width: 820px) and (orientation: portrait) {
  #pilares {
    & .container-btns {
      & img {
        width: 100%;
      }
    }

    & .scroll-gif {
      width: 15%;
    }

    & .retro-likes {
      & .texto-feedback {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  #pilares {
    overflow: hidden;

    & .container-btns {
      & img {
        width: 100%;
      }
    }

    & .scroll-gif {
      width: 15%;
    }
  }
}
