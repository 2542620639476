@import "../../../resources/scss/variables";

@font-face {
  font-family: "Monserrat Boldr";
  src: url("../../../resources/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Monserrat Regular";
  src: url("../../../resources/fonts/Montserrat-Regular.ttf");
}

#masterClass {
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 18px;
  color: $color-azul !important;
  font-family: "Monserrat Regular";

  & .titulos {
    font-family: "Monserrat Boldr";
    font-size: 36px;
    font-weight: bold;
    color: $color-hover;

    & span {
      background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  & p {
    color: $color-texto;
  }

  & .descripcion-salas {
    font-size: 18px;
  }

  & .description {
    // font-size: 14px;
    color: $color-hover;
  }

  & .contentenedor-chat {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  & .name-conferencista {
    display: inline-flex;
    margin-bottom: 30px;

    & .img-conferencista {
      width: 155px;
      height: 145px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 40px;
    }

    & .job-conferencista {
      font-weight: normal;

      font-size: 18px;

      & .name {
        // background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        color: $color-hover;
        font-size: 23px;
        font-weight: bold;
      }

      & .job {
        // background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        color: $color-hover;
        font-size: 16px;
        font-weight: bold;
      }
    }

    & .description-conferencista {
      color: $color-azul !important;

      font-size: 18px;
    }
  }

  & .container-sala {
    position: relative;
    border-radius: 15px;

    box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
    padding-bottom: 20px;

    &:hover {
      -webkit-box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
      box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
      // transform: scale(1.1);
    }

    & img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    & .pais {
      color: $color-normal;
      font-size: 22px;
      font-weight: bold;
      padding-top: 15px;

      padding-bottom: 20px;
    }

    & .btn-sala {
      background-color: #000;
      color: $color-azul;
      width: 120px;
      padding: 10px;
      text-align: center;
      margin: auto;
      cursor: pointer;

      &:hover {
        background-color: $color-normal;
        color: #000;
      }
    }
  }
}


@media screen and (max-width: 768px) and (orientation: portrait) {
  #masterClass {
    & .contentenedor-chat {
      height: 400px;
    }

    & .name-conferencista {
      display: inline-grid;
      text-align: center;

      & ul {
        text-align: left;
      }
    }
  }

  .contenedor-encuesta {
    & .titulo-encuesta {
      font-size: 15px;
    }

    & .pregunta-encuesta {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1180px) and (orientation: landscape) {
  #masterClass {
    & .contentenedor-chat {
      height: 400px;
    }

    & .name-conferencista {
      display: inline-grid;
      text-align: center;

      & ul {
        text-align: left;
      }
    }

    & .container-sala {
      & .btn-sala {
        width: 80px;
      }
    }
  }
}