@import '../../../resources/scss/variables';

#pilaresDaring {
	& .border {
		border-bottom: 5px solid #319641;
	}

	& #recursos-digitales {
		background-color: #f8f7f7;
		padding: 50px 0;
		margin-top: 50px;
		margin-bottom: 50px;

		& .contenedor-likes {
			display: flex;

			& .btn-likes {
				width: 50px;
				cursor: pointer;
			}
		}
	}
}