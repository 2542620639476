@import "../../../resources/scss/variables";

@font-face {
    font-family: "Montserrat Bold";
    src: url("../../../resources/fonts/Montserrat-Bold.ttf");
}

#kickoff {
    padding: 50px 0;

    & h1 {
        font-family: "Montserrat Bold";
        font-weight: bold;
        color: #319931;
    }

    & p {
        color: $color-texto;
    }
}

// @media screen and (max-width: 765px) and (orientation: portrait) {}

@media screen and (min-width: 325px) and (max-width: 819px) and (orientation: portrait) {}

@media screen and (max-width: 1200px) and (orientation: landscape) {}