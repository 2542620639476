@import '../../../../resources/scss/variables';

@font-face {
	font-family: 'Montserrat Regular';
	src: url('../../../../resources/fonts/Montserrat-Regular.ttf');
}

@font-face {
	font-family: 'Montserrat Bold';
	src: url('../../../../resources/fonts/Montserrat-Bold.ttf');
}

#actualizacion {
	padding: 0 0 25px 0;

	& h1 {
		color: $color-hover;
		font-family: 'Montserrat Bold';
	}

	& .swal2-confirm {
		background-color: #c1212a !important;
	}

	& .cancelar {
		line-height: 50px !important;
		height: 50px !important;
		text-align: center !important;
		width: 170px !important;
		cursor: pointer !important;
		background-color: rgba(209, 8, 8, 0.8);

		&:hover {
			background: transparent !important;
			border: 2px solid rgba(209, 8, 8, 0.8) !important;
			color: rgba(209, 8, 8, 0.8) !important;
		}
	}

	& .list-name {
		// cursor: pointer;
		padding: 5px 0;
		border-bottom: 1px solid #ccc;

		&:last-child {
			border-bottom: none;
		}

		&:hover {
			background: #ccc;
		}
	}

	& .MuiButton-root {
		width: 170px;
		background: $color-azul;
		color: #fff !important;
		padding: 5px;
		cursor: pointer;
		font-size: 14px;
		text-align: center;
		border-radius: 0px;
		text-transform: capitalize;
		font-weight: bold;
		font-family: 'Montserrat Regular';

		&:hover {
			background: transparent !important;
			border: 2px solid $color-azul !important;
			color: $color-azul !important;
		}
	}
}

.contenedor-comparar {
	background-color: #fff;
	padding: 20px;
	width: 100%;
	& p {
		padding: 5px;
	}
}

.igual {
	background-color: rgba($color: rgb(102, 255, 0), $alpha: 0.5);
}
.noigual {
	background-color: rgba($color: red, $alpha: 0.5);
}
