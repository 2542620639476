.alerta {
  position: fixed;
  z-index: 999;
  top: 113px;
  width: 450px !important;
  right: 10px !important;
  transition: all 0.5s ease-in-out;
}

.salida {
  transform: translateX(500px);
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
.shake-animation {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  // background-color: #ec6402 !important;
  // color: white !important;
}

// Celulares
@media (max-width: 425px) {
  .alerta {
    width: 80% !important;
    right: 10px !important;
  }
  .salida {
    transform: translateX(400px);
  }
}

// Tablets
@media (min-width: 426px) and (max-width: 900px) {
  .alerta {
    width: 40% !important;
    right: 10px !important;
  }
  .salida {
    transform: translateX(600px);
  }
}

// Computadoras
@media (min-width: 901px) {
  .alerta {
    width: 30% !important;
    right: 10px !important;
  }
  .salida {
    transform: translateX(700px);
  }
}
